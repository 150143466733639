export default [
  {
    path: '/parceiro',
    name: 'dashboard-affiliate',
    meta: { layout: 'partner' },
    component: () => import('../pages/partners/index.vue')
  },
  {
    path: '/parceiro/procedimentos',
    name: 'bff-partners-procedures',
    meta: { layout: 'partner' },
    component: () => import('../pages/partners/products/index.vue')
  },
  {
    path: '/parceiro/procedimento/:id',
    name: 'bff-partners-procedure-details',
    meta: { layout: 'partner' },
    component: () => import('../pages/partners/products/edit.vue')
  },
  {
    path: '/parceiro/minha-conta',
    name: 'bff-partners-my-account',
    meta: { layout: 'partner' },
    component: () => import('../pages/partners/myaccount/index.vue')
  },
  {
    path: '/parceiro/proposta/:id?',
    name: 'bff-proposals',
    meta: { layout: 'empty' },
    component: () => import('../pages/proposals/index.vue')
  },
  {
    path: '/parceiro/carteira',
    name: 'bff-partners-wallet',
    meta: { layout: 'partner' },
    component: () => import('../pages/partners/wallet/index.vue')
  },
  {
    path: '/parceiro/propostas',
    name: 'bff-partners-proposals',
    meta: { layout: 'partner' },
    component: () => import('../pages/partners/proposals/index.vue')
  },
  {
    path: '/parceiro/proposta/:id',
    name: 'bff-partners-proposal-details',
    meta: { layout: 'partner' },
    component: () => import('../pages/partners/proposals/edit.vue')
  },

  // ROTAS DOS CLIENTES (MEMBERS)
  {
    path: '/meu-planejamento/:id',
    name: 'bff-proposal-details',
    meta: { layout: 'member' },
    component: () => import('../pages/members/dasboard/proposal/details.vue')
  },
  {
    path: '/seus-planejamentos',
    name: 'dashboard-bff',
    meta: { layout: 'member' },
    component: () => import('../pages/members/dasboard/index.vue')
  }
]
