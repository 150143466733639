export let menuDataPartners = [
  {
    path: '/parceiro',
    title: 'Dashboard',
    icon: 'ti-home',
    defaultActive: true,
    active: true,
    type: 'link'
  },
  {
    path: `/parceiro/minha-conta`,
    title: 'Minha conta',
    type: 'link',
    icon: 'ti-id-badge',
    defaultActive: false,
    active: false
  },
  {
    path: `/parceiro/procedimentos`,
    title: 'Procedimentos',
    type: 'link',
    icon: 'ti-shopping-cart-full',
    defaultActive: false,
    active: false
  },
  {
    path: `/parceiro/propostas`,
    title: 'Propostas',
    type: 'link',
    icon: 'ti-write'
  },
  {
    path: `/parceiro/carteira`,
    title: 'Carteira',
    type: 'link',
    icon: 'ti-wallet',
    active: false,
    defaultActive: false
  }
  // {
  //   path: `/parceiro/clientes`,
  //   title: 'Clientes',
  //   type: 'link',
  //   icon: 'ti-user',
  //   defaultActive: false,
  //   active: false
  // }
  // {
  //   path: `/parceiro/vendas`,
  //   title: 'Vendas',
  //   type: 'link',
  //   icon: 'ti-money',
  //   active: false,
  //   defaultActive: false
  // }
]
